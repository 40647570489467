import { useStaticQuery, graphql } from "gatsby";

export const query = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      company: allFile(filter: { name: { eq: "company.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              companyName
              slogan
              cidade
              subtitle
              aboutText
            }
          }
        }
      }
      contato: allFile(filter: { name: { eq: "contato.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              telefones {
                whatsapp
                fixos
                agendamento
                plantao
              }
              horarios {
                segunda
                terca
                quarta
                quinta
                sexta
                sabado
                domingo
              }
              endereco {
                rua
                cidade
                estado
                CEP
                addressLink
                key
                placeId
              }
            }
          }
        }
      }
      redes: allFile(filter: { name: { eq: "redes-sociais.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              facebook
              instagram {
                user
                post
              }
              linkedin
              whatsapp
              mensagem
              empresa
            }
          }
        }
      }
      servicos: allFile(filter: { name: { eq: "servicos.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              servicos {
                descricao
                titulo
                foto
                svg
                url
              }
            }
          }
        }
      }
      analytics: allFile(filter: { name: { eq: "analytics.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              gtm
              ga
              pixelValue
            }
          }
        }
      }
      popup: allFile(filter: { name: { eq: "pop-up-24h.metadata" } }) {
        nodes {
          childMetadatasJson {
            titulo
          }
        }
      }
      popUpImage: allImageSharp(
        filter: { original: { src: { regex: "/.*pop-up-emergencia.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED, width: 600, height: 300)
          }
        }
      }
      cta: allFile(filter: { name: { eq: "cta.metadata" } }) {
        nodes {
          childMetadatasJson {
            title
            subtitle
          }
        }
      }
      landingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
        }
      }
      logoLanding: allImageSharp(
        filter: { original: { src: { regex: "/.*/logo-landing.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 700
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      aboutImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-about.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      ctaImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-cta.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      instagramImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/logo-redes-sociais.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      logoTopbar: allImageSharp(
        filter: { original: { src: { regex: "/.*logo-topbar.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 300
              height: 200
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      logoFooter: allImageSharp(
        filter: { original: { src: { regex: "/.*logo-footer.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 300
              height: 200
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      serviceLandingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/servicos-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      logoMonoMainImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/logo-monomain.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 250
              height: 250
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      servicePageMetadata: allFile(
        filter: { name: { eq: "servicos-page.metadata" } }
      ) {
        edges {
          node {
            childMetadatasJson {
              subtitle
              title
            }
          }
        }
      }
      galeriaLandingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/galeria-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      galeriaPageMetadata: allFile(
        filter: { name: { eq: "galeria-page.metadata" } }
      ) {
        edges {
          node {
            childMetadatasJson {
              subtitle
              title
            }
          }
        }
      }
      photos: allImageSharp(
        filter: { original: { src: { regex: "/.*gallery-img.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      contatoLandingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/contato-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      contatoPageMetadata: allFile(
        filter: { name: { eq: "contato-page.metadata" } }
      ) {
        edges {
          node {
            childMetadatasJson {
              subtitle
              title
            }
          }
        }
      }
      serviceLandingPhotos: allImageSharp(
        filter: { original: { src: { regex: "/.*descricao.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
            original {
              src
            }
          }
        }
      }
      products: allProdutosMetadataJson {
        edges {
          node {
            categorias {
              imgFilename
              nome
            }
            produtos {
              apresentation
              brand
              category
              flavor
              howToUse
              imgFilename
              name
            }
            subtitle
            title
          }
        }
      }
      productsItemImgs: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-petshop-item-*/" } } }
      ) {
        nodes {
          gatsbyImageData
          original {
            src
          }
        }
      }
      productsCategoriaImgs: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-categoria-*/" } } }
      ) {
        nodes {
          gatsbyImageData
          original {
            src
          }
        }
      }
       googleAverage: googleAverageReview {
         name
         rating
         user_ratings_total
       }
       googleComments: allGoogleReview {
         nodes {
           author_name
           photo {
             childImageSharp {
               gatsbyImageData(placeholder: BLURRED)
             }
           }
           profile_photo_url
           rating
           relative_time_description
           text
         }
       }
      covenantsImages: allImageSharp(
        filter: { original: { src: { regex: "/.*convenio-logo.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      aboutDestaque: allImageSharp(
        filter: { original: { src: { regex: "/.*img-servico-destaque.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      AlertImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-alert.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      respostasGpt: allFile(filter: { name: { eq: "data.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              responses {
                h2
                about1
                about2
                descriptionService
                whatsMessage
              }
            }
          }
        }
      }
      videos: allFile(filter: { name: { regex: "/.*landing-vid.*/" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
      countdown: allFile(
        filter: { name: { eq: "countdown.container.metadata" } }
      ) {
        nodes {
          childMetadatasJson {
            eventDate
            eventName
            subtitle
          }
        }
      }
      image1: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-1.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image2: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-2.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image3: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-3.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image4: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-4.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image5: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-5.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      imageAbout1: allImageSharp(
        filter: { original: { src: { regex: "/.*/imageAbout-1.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      imageAbout2: allImageSharp(
        filter: { original: { src: { regex: "/.*/imageAbout-2.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);

  return data;
};
